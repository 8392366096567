export function formatDate(value: string) {
  // Remove any non-digit characters
  const inputValue = value.replace(/\D/g, '')

  // Split the value into day, month, and year parts
  const day = inputValue.slice(0, 2)
  const month = inputValue.slice(2, 4)
  const year = inputValue.slice(4, 8)

  // Create the formatted date string
  let formattedDate = ''

  if (day) {
    formattedDate += day
    if (day.length === 2) {
      formattedDate += '/'
    }
  }

  if (month) {
    formattedDate += month
    if (month.length === 2) {
      formattedDate += '/'
    }
  }

  formattedDate += year

  // Update the input value with the formatted date
  return formattedDate
}

export function expiryDate(value: string) {
  // Remove caracteres não numéricos
  const cleanedValue = value.replace(/\D/g, '')

  // Aplica a máscara usando regex (MM/AAAA)
  const formattedValue = cleanedValue.replace(/(\d{2})(\d{0,4})/, '$1/$2')

  return formattedValue
}

export function formatUF(value: string) {
  console.log(value.replace(/\d+/g, ''))
  return value.replace(/\d+/g, '').toLocaleUpperCase()
}
export function formatZipCode(value: string) {
  return value
    .replace(/\D/g, '') // Remove caracteres não numéricos
    .replace(/(\d{5})(\d)/, '$1-$2') // Adiciona o hífen após os primeiros 5 dígitos
}

export function formatCreditCard(value: string) {
  // Remove caracteres não numéricos e espaços em branco
  const cleanedValue = value.replace(/\D/g, '')

  // Formata o número em grupos de 4 dígitos separados por espaços
  const formattedValue = cleanedValue.replace(/(\d{1,4})/g, '$1 ')

  // Retorna o número formatado
  return formattedValue.trim()
}

export function formatPhone(value: string) {
  value = value
    .replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace('+', '')
    .trim()
  if (value.length > 12) value = value.substring(2)
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)$/, '$1')
}

export function formatCpfCNPJ(e: any) {
  let value = e.target.value
  // value = value.replace('.', '').replace('(', '').replace(')', '').trim()
  // if (value.length >= 14) return value
  if (value.length === 11) {
    value = value
      .replace(/\D/g, '') // remove tudo que não é dígito
      .replace(/(\d{3})(\d)/, '$1.$2') // coloca ponto entre o terceiro e o quarto dígito
      .replace(/(\d{3})(\d)/, '$1.$2') // coloca ponto entre o sétimo e o oitavo dígito
      .replace(/(\d{3})(\d{1,2})/, '$1-$2') // coloca hífen entre o décimo primeiro e o décimo segundo dígito
      .replace(/(-\d{2})\d+?$/, '$1') // garante que só terá no máximo 14 caracteres
  } else if (value.length === 14) {
    // Formar CNPJ
    value = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }
  e.target.value = value
  // console.log('value:', value.length, value)
  // console.log('targer', e.target.value, e.target.value.length)
  return e.target.value
}
