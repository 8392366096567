import Button from '@mui/material/Button'
import { CheckCircle } from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { apiGate } from 'service/api'

export const ThanksPage = () => {
  const { id } = useParams()

  const { data: planData, isLoading: isLoadingPlan } = useQuery({
    queryKey: [id],
    queryFn: getPlan,
    refetchOnWindowFocus: false,
  })

  async function getPlan() {
    try {
      const { data } = await apiGate.get(`subscriptions/${id}`)
      console.log(data)
      return data
    } catch (err: unknown) {
      console.error(err)
    }
  }

  const userName = planData?.customer.name
  const planName = planData?.plan.name
  const trialPeriodDays = planData?.plan.trial_period_days

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <CheckCircle size={78} color="#34CBAB" weight="fill" />

      <h1 className="mt-1 text-2xl font-normal text-[#282828] text-center">
        Pagamento confirmado!
      </h1>

      <span className="text-base my-2 text-[#282828] font-[Roboto] text-center block">
        Obrigado {userName} por adquirir o <b>{planName}</b>
      </span>

      <strong className="mt-1 text-lg font-normal text-[#282828] mb-5 block text-center">
        Seu tempo de degustação é de {trialPeriodDays} dias e você já pode
        começar a usar o seu plano
      </strong>

      <Button variant="contained" onClick={() => (window.location.href = '/')}>
        Voltar para home
      </Button>
    </div>
  )
}
