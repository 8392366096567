// import { TypesSubscriptions, typeStatus } from 'constants/constants'
import '../../index.css'
import {
  About,
  AboutLeads,
  AboutWithIA,
  Footer,
  Intro,
  Plans,
} from 'components'

export const Lp = () => {
  return (
    <main className="main">
      <Intro />
      <About />
      <AboutWithIA />
      <AboutLeads />
      <Plans />
      <Footer />
    </main>
  )
}
