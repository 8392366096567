export const PlansDetails = [
  {
    id: 2,
    type: 'Broker',
    infos: [
      {
        title: 'Degustação',
        info: '(07 Dias)',
      },
      {
        title: 'Empreendimentos - Cadastro',
        info: '(40 Empreendimentos)',
      },
      {
        title: 'Mais de 30 características qualificadas do imóvel',
        info: '(Ok)',
      },
      {
        title: 'Aplicativo para computador e smartphone',
        info: '(Ok)',
      },
      {
        title: 'Links direto às pastas compartilhadas na nuvem',
        info: '(Ok)',
      },
      {
        title: 'Georreferenciamento dos empreendimentos',
        info: '(Ok)',
      },
      // {
      //   title: 'Extrato do empreendimento',
      //   info: '(-)',
      // },
      {
        title: 'Acesso aos dados cadastrados de forma rápida e direta',
        info: '(Ok)',
      },
      {
        title: 'Rota GPS para o empreendimento selecionado - Google Rotas',
        info: '(Ok)',
      },
      {
        title:
          'Informação rápida sobre renda e investimento, metragens, número de dormitórios do imóvel',
        info: '(Ok)',
      },
      {
        title:
          'Visita virtual ao local do empreendimento - Google - Street View',
        info: '(Ok)',
      },
      {
        title:
          'Assistente IA para comunicação escrita com os clientes - Créditos',
        info: '(74.059)',
      },
      {
        title: 'Participação em equipes',
        info: '(até 02 Equipes)',
      },
      // {
      //   title:
      //     'Gestão de equipe com perfis de cadastro e consulta. Número de integrantes *',
      //   info: '(-)',
      // },
      {
        title: 'Usuários',
        info: '(01 Licença de Usuário)',
      },
    ],
    price: 57.44,
  },
  {
    id: 3,
    type: 'Advanced',
    infos: [
      {
        title: 'Degustação',
        info: '(07 Dias)',
      },
      {
        title: 'Empreendimentos - Cadastro',
        info: '(100 Empreendimentos)',
      },
      {
        title: 'Mais de 30 características qualificadas do imóvel',
        info: '(Ok)',
      },
      {
        title: 'Aplicativo para computador e smartphone',
        info: '(Ok)',
      },
      {
        title: 'Links direto às pastas compartilhadas na nuvem',
        info: '(Ok)',
      },
      {
        title: 'Georreferenciamento dos empreendimentos',
        info: '(Ok)',
      },
      {
        title: 'Extrato do empreendimento',
        info: '(Ok)',
      },
      {
        title: 'Acesso aos dados cadastrados de forma rápida e direta',
        info: '(Ok)',
      },
      {
        title: 'Rota GPS para o empreendimento selecionado - Google Rotas',
        info: '(Ok)',
      },
      {
        title:
          'Informação rápida sobre renda e investimento, metragens, número de dormitórios do imóvel',
        info: '(Ok)',
      },
      {
        title:
          'Visita virtual ao local do empreendimento - Google - Street View',
        info: '(Ok)',
      },
      {
        title:
          'Assistente IA para comunicação escrita com os clientes - Créditos',
        info: '(126.723)',
      },
      // {
      //   title: 'Participação de equipes',
      //   info: '(-)',
      // },
      {
        title: 'Gestão de equipe com perfis de cadastro e consulta. ',
        info: '(Equipe com até 10 integrantes)',
      },
      {
        title: 'Usuários',
        info: '(01 Licença Administrador e 01 Licença User)',
      },
    ],
    price: 97.91,
  },
  {
    id: 4,
    type: 'Business',
    infos: [
      {
        title: 'Degustação',
        info: '(07 Dias)',
      },
      {
        title: 'Empreendimentos - Cadastro',
        info: '(350 Empreendimentos)',
      },
      {
        title: 'Mais de 30 características qualificadas do imóvel',
        info: '(Ok)',
      },
      {
        title: 'Aplicativo para computador e smartphone',
        info: '(Ok)',
      },
      {
        title: 'Links direto às pastas compartilhadas na nuvem',
        info: '(Ok)',
      },
      {
        title: 'Georreferenciamento dos empreendimentos',
        info: '(Ok)',
      },
      {
        title: 'Extrato do empreendimento',
        info: '(Ok)',
      },
      {
        title: 'Acesso aos dados cadastrados de forma rápida e direta',
        info: '(Ok)',
      },
      {
        title: 'Rota GPS para o empreendimento selecionado - Google Rotas',
        info: '(Ok)',
      },
      {
        title:
          'Informação rápida sobre renda e investimento, metragens, número de dormitórios do imóvel',
        info: '(Ok)',
      },
      {
        title:
          'Visita virtual ao local do empreendimento - Google - Street View',
        info: '(Ok)',
      },
      {
        title:
          'Assistente IA para comunicação escrita com os clientes - Créditos',
        info: '(279.780)',
      },
      {
        title: 'Gestão de equipe com perfis de cadastro e consulta.',
        info: '(Equipe com até 50 integrantes)',
      },
      {
        title: 'Usuários',
        info: '(01 Licença Administrador e 03 Licenças User)',
      },
    ],
    price: 194.53,
  },
  {
    id: 1,
    type: 'User',
    infos: [
      {
        title: 'Degustação',
        info: '(7 Dias)',
      },
      {
        title: 'Empreendimentos - Cadastro',
        info: '(Equipe)',
      },
      {
        title: 'Mais de 30 características qualificadas do imóvel',
        info: '(Ok)',
      },
      {
        title: 'Aplicativo para computador e smartphone',
        info: '(Ok)',
      },
      {
        title: 'Links direto às pastas compartilhadas na nuvem',
        info: '(Ok)',
      },
      {
        title: 'Georreferenciamento dos empreendimentos',
        info: '(Ok)',
      },
      {
        title: 'Extrato do empreendimento',
        info: '(Equipe)',
      },
      {
        title: 'Acesso aos dados cadastrados de forma rápida e direta',
        info: '(Ok)',
      },
      {
        title: 'Rota GPS para o empreendimento selecionado - Google Rotas',
        info: '(Ok)',
      },
      {
        title:
          'Informação rápida sobre renda e investimento, metragens, número de dormitórios do imóvel',
        info: '(Ok)',
      },
      {
        title:
          'Visita virtual ao local do empreendimento - Google - Street View',
        info: '(Ok)',
      },
      {
        title:
          'Assistente IA para comunicação escrita com os clientes - Créditos',
        info: '(Equipe)',
      },
      {
        title: 'Participação em equipes',
        info: '(01 Equipe)',
      },
      // {
      //   title:
      //     'Gestão de equipe com perfis de cadastro e consulta. Número de integrantes *',
      //   info: '(-)',
      // },
      {
        title: 'Usuários',
        info: '(01 Licença para Integrante de Equipe)',
      },
    ],
    price: 48.31,
  },
]
