import { Container, Box, Button, Typography, Stack } from '@mui/material'
import { aboutBackgroundImage, aboutBusinessMensImage } from '../assets'
import { Separator } from './Separator'

export const About: React.FC = () => {
  return (
    <>
      <Separator />
      <Box
        sx={{
          backgroundImage: `url(${aboutBackgroundImage})`,
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          paddingBlock: { lg: '65px', xs: '13px' },
          paddingInline: { lg: '180px', sm: '40px' },
          marginTop: {
            lg: '-130px',
            xs: '-150px',
          },
        }}
      >
        <Container
          sx={{
            zIndex: 999,
            position: 'relative',
          }}
        >
          <Stack alignItems={{ lg: 'flex-end', xs: 'center' }}>
            <Typography
              paragraph
              sx={{
                fontSize: { lg: 24, xs: 18 },
                maxWidth: {
                  lg: 530,
                  xs: '100%',
                },
                textAlign: {
                  lg: 'left',
                  xs: 'center',
                },
              }}
            >
              Com mais de 30 característica de habitação para cadastramento,
              essas e outras perguntas serão imediatamente respondidas:
            </Typography>
          </Stack>

          <Typography
            display="block"
            component="strong"
            fontWeight={700}
            sx={{
              fontSize: {
                lg: 40,
                xs: 20,
              },
              maxWidth: 420,
              margin: {
                lg: '0',
                xs: '0 auto',
              },
              textAlign: {
                lg: 'left',
                xs: 'center',
              },
            }}
          >
            O imóvel tem Rooftop? Valor do investimento? Renda mínima? Metragem?
          </Typography>

          <Stack
            sx={{
              marginBottom: {
                lg: 0,
                xs: '40px',
              },
            }}
            alignItems={{ lg: 'flex-end', xs: 'center' }}
          >
            <Typography
              component="strong"
              fontWeight={700}
              sx={{
                fontSize: {
                  lg: 40,
                  xs: 20,
                },
                maxWidth: 530,
                margin: {
                  lg: '0',
                  xs: '0 auto',
                },
                textAlign: {
                  lg: 'left',
                  xs: 'center',
                },
              }}
            >
              Onde estão as fotos do imóvel?
            </Typography>

            <Typography
              component="strong"
              fontWeight={700}
              sx={{
                fontSize: {
                  lg: 40,
                  xs: 20,
                },
                maxWidth: 530,
                margin: {
                  lg: '0',
                  xs: '0 auto',
                },
                textAlign: {
                  lg: 'left',
                  xs: 'center',
                },
              }}
            >
              Como eu faço para ir até o imóvel?
            </Typography>
          </Stack>

          <Box
            display="flex"
            justifyContent={{
              lg: 'flex-start',
              xs: 'center',
            }}
          >
            <img
              src={aboutBusinessMensImage}
              alt="Dois homens assinando um contrato com crianças brincando de fundo"
            />
          </Box>

          <Stack
            alignItems={{ lg: 'flex-end', xs: 'center' }}
            sx={{
              marginTop: {
                lg: 0,
                xs: '40px',
              },
            }}
          >
            <Stack direction="column" alignItems="center">
              <Typography
                paragraph
                sx={{
                  fontSize: { lg: 24, xs: 18 },
                  maxWidth: {
                    lg: 528,
                    xs: '100%',
                  },
                  textAlign: {
                    lg: 'left',
                    xs: 'center',
                  },
                }}
              >
                Com a tecnologia voltada ao uso do Corretor, as informações de
                toda a carteira de imóveis estão a poucos “toques” de distância.
              </Typography>

              <Button
                variant="contained"
                href="#plans"
                sx={{
                  backgroundColor: '#ffffff',
                  color: '#000000',
                  fontWeight: 600,
                  fontSize: { lg: 21, xs: 18 },
                  marginBlock: {
                    lg: '40px',
                    xs: '30px',
                  },
                  height: 42,
                  maxWidth: 317,
                  paddingInline: '16px',
                  paddingBlock: '0px',
                  ':hover': {
                    backgroundColor: '#000000',
                    color: '#ffffff',
                  },
                  textAlign: 'center',
                }}
              >
                Quero converter mais
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Box
        sx={{
          marginTop: '-180px',
        }}
      >
        <div className="bottom-separator" />
      </Box>
    </>
  )
}
