import { Separator } from './Separator'
import { Container, Box, Button, Typography, Stack } from '@mui/material'
import { aboutIABackgroundImage, devicesWithMaps } from '../assets'

export const AboutWithIA: React.FC = () => {
  return (
    <>
      <Separator />
      <Box
        sx={{
          backgroundImage: `url(${aboutIABackgroundImage})`,
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          paddingBlock: { lg: '65px', xs: '13px' },
          paddingInline: { lg: '180px', sm: '40px' },
          marginTop: { lg: '-190px', xs: '-150px' },
        }}
      >
        <Container
          sx={{
            zIndex: 999,
            position: 'relative',
          }}
        >
          <Stack alignItems={{ lg: 'flex-end', xs: 'center' }}>
            <Typography
              paragraph
              sx={{
                fontSize: { lg: 24, xs: 18 },
                maxWidth: {
                  lg: 530,
                  xs: '100%',
                },
                textAlign: {
                  lg: 'left',
                  xs: 'center',
                },
              }}
            >
              Vincule ao{' '}
              <Typography
                sx={{
                  fontSize: { lg: 24, xs: 18 },
                }}
                component="span"
                fontWeight={700}
              >
                GIMOV
              </Typography>{' '}
              suas pastas digitais, como o Google Drive e o OneDrive e nunca
              mais se preocupe em localizar a documentação do imóvel.
            </Typography>
          </Stack>

          <Typography
            display="block"
            component="strong"
            fontWeight={700}
            sx={{
              fontSize: {
                lg: 40,
                xs: 20,
              },
              maxWidth: {
                lg: 1080,
                xs: '100%',
              },
              margin: '0 auto',
              textAlign: 'center',
            }}
          >
            Com o uso inteligente das tecnologias disponíveis, tenha muito mais,
            com muito menos investimentos.
          </Typography>

          <Box display="flex" alignItems="center" justifyContent="center">
            <img
              src={devicesWithMaps}
              alt="Um celular, um tablet e um notebook mostrando um mapa com uma localização específica marcada no mapa"
            />
          </Box>

          <Stack
            alignItems={{ lg: 'flex-start', xs: 'center' }}
            justifyContent={'space-between'}
            direction={{ lg: 'row', xs: 'column' }}
            sx={{
              marginTop: {
                lg: 0,
                xs: '40px',
              },
            }}
          >
            <Typography
              paragraph
              sx={{
                fontSize: { lg: 24, xs: 18 },
                maxWidth: {
                  lg: 528,
                  xs: '100%',
                },
                textAlign: {
                  lg: 'left',
                  xs: 'center',
                },
              }}
            >
              Obtenha o auxílio da{' '}
              <Typography
                sx={{ fontSize: { lg: 24, xs: 18 } }}
                component="span"
                fontWeight={700}
              >
                Inteligência Artificial
              </Typography>{' '}
              para elaborar sua comunicação escrita com seus clientes.
            </Typography>

            <Button
              variant="contained"
              href="#plans"
              sx={{
                backgroundColor: '#ffffff',
                color: '#000000',
                fontWeight: 600,
                fontSize: { xl: 24, xs: 18 },
                marginTop: {
                  lg: '40px',
                  xs: '20px',
                },
                maxWidth: 413,
                paddingInline: '13px',
                ':hover': {
                  backgroundColor: '#000000',
                  color: '#ffffff',
                },
              }}
            >
              Quero realizar mais sonhos
            </Button>
          </Stack>

          <Typography
            display="block"
            component="strong"
            fontWeight={700}
            sx={{
              fontSize: {
                lg: 40,
                xs: 20,
              },
              marginTop: { lg: '106px', xs: '40px' },
              textAlign: 'center',
            }}
          >
            Então, como aumentar a conversão de leads?
          </Typography>
        </Container>
      </Box>

      <Box
        sx={{
          marginTop: '-180px',
        }}
      >
        <div className="bottom-separator" />
      </Box>
    </>
  )
}
