import axios from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Input } from 'components/Input'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import {
  formatCpfCNPJ,
  formatDate,
  formatPhone,
  formatZipCode,
} from 'utils/masks'
import { CaretCircleLeft, CaretCircleRight } from '@phosphor-icons/react'
import { removeMask } from 'utils/removeMask'
import { UF } from 'utils/dadosUF'

export default function InfoPessoais({
  isFormPmto,
  setIsFormPmto,
  client,
  setClient,
}) {
  const navigate = useNavigate()
  const [isCEPValid, setIsCEPValid] = useState(true)

  const createClientFormSchema = z.object({
    email: z
      .string()
      .min(1, 'Informe um email!')
      .max(64, 'Excesso de caracteres!')
      .email('Formato de email inválido!')
      .toLowerCase(),
    name: z
      .string()
      .regex(
        /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
        'Informe um nome válido',
      ),
    telefone: z
      .string()
      .min(15, 'Informe número de celular!')
      .regex(
        /^\([1-9]{2}\) (?:[2-8]|9[0-9])[0-9]{3}-[0-9]{4}$/,
        'Informe um número de celular válido!',
      )
      .transform((telefone) => formatPhone(telefone)),
    nascimento: z
      .string()
      .min(10, 'Informe uma data')
      .regex(
        /([0-2][0-9]|3[0-1])\/(0[0-9]|1[0-2])\/[0-9]{4}/,
        'Informe uma data válida!',
      ),
    cpf: z.string().min(11, 'Informe um número valido'),
    // .regex(
    //   /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/,
    //   'Informe um valor válido......!',
    // ),
    // .transform((cpf) => formatCpf(cpf)),
    cep: z.string().min(9, 'Informe um CEP válido.').max(10),
    endereco: z
      .string()
      .min(3, 'Informe um endereço válido')
      .max(150, 'Número excedido de caracteres!'),
    numero: z.string().max(10).min(1, 'Informe um número válido'),
    complemento: z.string().max(100, 'Número excedido de caracteres!'),
    bairro: z.string().min(0).max(100, 'Número excedido de caracteres!'),
    cidade: z
      .string()
      .min(3, 'Informe um cidade válida!')
      .max(100, 'Número excedido de caracteres!'),
    uf: z
      .string()
      .min(2, 'Informe uma UF válida!')
      .max(2, 'Número excedido de caracteres!')
      .refine((data) => data !== 'UF', 'UF inválida!'),
  })

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(createClientFormSchema),
  })

  async function getAddress(e) {
    if (e.target.value.length < 9) {
      setError('cep', { message: 'Insira um CEP válido' })
    } else {
      clearErrors('cep')
    }

    try {
      if (e.target.value.length === 9) {
        const { data } = await axios.get(
          `https://viacep.com.br/viacep.com.br/ws/${removeMask(
            e.target.value,
          )}/json/`,
        )
        // console.log('Endereço', data)
        setValue('endereco', data.logradouro)
        setValue('complemento', data.complemento)
        setValue('bairro', data.bairro)
        setValue('cidade', data.localidade)
        setValue('uf', data.uf)
        setIsCEPValid(true)
      }
    } catch (error) {}
  }

  function loadData() {
    if (client) {
      setValue('bairro', client.bairro)
      setValue('cep', client.cep)
      setValue('cidade', client.cidade)
      setValue('complemento', client.complemento)
      setValue('cpf', client.cpf)
      setValue('email', client.email)
      setValue('endereco', client.endereco)
      setValue('name', client.name)
      setValue('numero', client.numero)
      setValue('telefone', client.telefone)
      setValue('uf', client.uf)
      setValue('nascimento', client.nascimento)
    }
  }

  function createClient(data) {
    const _client = JSON.parse(JSON.stringify(data))
    setClient(_client)
    setIsFormPmto(true)
    // console.log('Client', _client)
  }
  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      <form onSubmit={handleSubmit(createClient)} className="flex-col">
        <section>
          <strong className="text-slate-800">Informações Pessoais</strong>

          <div className="mt-3">
            <Input
              {...register('email')}
              label="E-mail*"
              value={client.email}
            />
            {errors.email && (
              <span className="text-sm text-red-500">
                {errors.email.message}
              </span>
            )}
          </div>
          <div className="mt-5 grid grid-cols-2 gap-4">
            <div>
              <Input {...register('name')} label="Nome completo*" />
              {errors.name && (
                <span className="text-sm text-red-500">
                  {errors.name.message}
                </span>
              )}
            </div>
            <div>
              <Input
                {...register('nascimento', {
                  minLength: 10,
                  onChange: (e) =>
                    setValue('nascimento', formatDate(e.target.value)),
                })}
                label="Data de nascimento*"
              />
              {errors.nascimento && (
                <span className="text-sm text-red-500">
                  {errors.nascimento.message}
                </span>
              )}
            </div>
          </div>

          <div className="mt-5 grid grid-cols-2 gap-4">
            {/* <div>
              <FormControl fullWidth size="small">
                <InputLabel id="tpdoc">Tipo</InputLabel>
                <Select>
                  <MenuItem value="pf">PF</MenuItem>
                  <MenuItem value="pj">PJ</MenuItem>
                </Select>
              </FormControl>
            </div> */}
            <div>
              <Input
                {...register('cpf', {
                  // minLength: 11,
                  // onChange: (e) => setValue('cpf', formatCpf(e)),
                  onBlur: (e) => setValue('cpf', formatCpfCNPJ(e)),
                })}
                maxLength={14}
                label="CPF/CNPJ*"
                // error={errors.cpf ? 'Insira um CPF válido' : ''}
                // required
              />
              {errors.cpf && (
                <span className="text-sm text-red-500">
                  {errors.cpf.message}
                </span>
              )}
            </div>
            <div>
              <Input
                {...register('telefone', {
                  minLength: 15,
                  onChange: (e) =>
                    setValue('telefone', formatPhone(e.target.value)),
                })}
                label="Celular*"
              />
              {errors.telefone && (
                <span className="text-sm text-red-500">
                  {errors.telefone.message}
                </span>
              )}
            </div>
          </div>
        </section>

        <section>
          <div className="mt-5">
            <strong className="text-slate-800">Informações de endereço</strong>
            <div className="mt-3 grid grid-cols-6">
              <div className="col-span-2">
                <Input
                  maxLength={9}
                  {...register('cep', {
                    minLength: 9,
                    onChange: (e) => {
                      setValue('cep', formatZipCode(e.target.value))
                      getAddress(e)
                    },
                  })}
                  label="CEP*"
                  // error={errors.cep ? 'Insira um CEP válido' : ''}
                  // required
                />
                {errors.cep && (
                  <span className="text-sm text-red-500">
                    {errors.cep.message}
                  </span>
                )}
              </div>
              <div className="ml-3 justify-self-center">
                <select
                  id="uf"
                  {...register('uf', {
                    minLength: 2,
                    onChange: (e) => {
                      setValue('uf', e.target.value)
                    },
                  })}
                  className=" border h-12 w-16 border-gray-300 text-sm rounded-lg bg-stone-100 text-gray-900 block p-2.5 focus:right-0 focus:outline-none  focus:border-gray-400"
                >
                  <option key="UF" value="UF" defaultChecked>
                    UF
                  </option>
                  {UF.map((_uf) => (
                    // eslint-disable-next-line react/jsx-key
                    <option key={_uf.sigla} value={_uf.sigla}>
                      {_uf.sigla}
                    </option>
                  ))}
                </select>
                {errors.uf && (
                  <span className="text-sm text-red-500">
                    {errors.uf.message}
                  </span>
                )}
              </div>
              <div className="ml-3 col-span-3">
                <Input
                  maxLength={50}
                  {...register('cidade')}
                  label="Cidade*"
                  // required
                />
                {errors.cidade && (
                  <span className="text-sm text-red-500">
                    {errors.cidade.message}
                  </span>
                )}
              </div>
            </div>
            <div className="mt-5 grid grid-cols-2 gap-4">
              <div>
                <Input
                  maxLength={150}
                  {...register('endereco')}
                  label="Endereço*"
                />
                {errors.endereco && (
                  <span className="text-sm text-red-500">
                    {errors.endereco.message}
                  </span>
                )}
              </div>
              <div>
                <Input maxLength={9} {...register('numero')} label="Número*" />
                {errors.numero && (
                  <span className="text-sm text-red-500">
                    {errors.numero.message}
                  </span>
                )}
              </div>
            </div>
            <div className="mt-5 grid grid-cols-2 gap-4">
              <div>
                <Input
                  maxLength={100}
                  {...register('complemento')}
                  label="Complemento"
                />
              </div>
              <div>
                <Input
                  maxLength={50}
                  {...register('bairro')}
                  label="Bairro"
                  // required
                />
                {errors.bairro && (
                  <span className="text-sm text-red-500">
                    {errors.bairro.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full justify-between mt-6">
            <div>
              <button
                className="flex gap-1 items-center text-[#1b1b1b] font-semibold text-lg "
                type="button"
                onClick={() => navigate('/', { replace: true })}
              >
                <CaretCircleLeft color="#1b1b1b" size={32} weight="fill" />
                Voltar
              </button>
            </div>
            <div>
              <button
                className="flex gap-1 items-center text-[#1b1b1b] font-semibold text-lg "
                type="submit"
              >
                <CaretCircleRight color="#1b1b1b" size={32} weight="fill" />
                Avançar
              </button>
            </div>
          </div>
        </section>
      </form>
    </>
  )
}
