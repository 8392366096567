import axios from 'axios'

export const apiGate = axios.create({
  // baseURL: process.env.REACT_APP_URL_API_GATE?.toString(),
  // Não usar variaveis de ambiente para definir rota  para o axios
  baseURL: 'https://api.gate51.com.br/gimov',
  // baseURL: 'http://localhost:3001/gimov',
  headers: {
    Authorization: process.env.REACT_APP_KG,
  },
})
