import { Backdrop, Box, Grid, Modal } from '@mui/material'
import TermodeUso from './termodeuso'
import PoliticaPrivacidade from './politicaprivacidade'

export const Termos = ({
  openModal,
  setOpenModal,
  tpModal,
  height,
  width,
  position,
}) => {
  const style = {
    height,
    width,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
  }

  return (
    <Grid>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          {tpModal === 'T' && <TermodeUso />}
          {tpModal === 'P' && <PoliticaPrivacidade />}
        </Box>
      </Modal>
    </Grid>
  )
}
