import { Container, Box, Typography, Stack } from '@mui/material'
import { aboutLeadsDevicesImage, aboutLeadsImage } from '../assets'
import { Separator } from './Separator'

export const AboutLeads: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#D9D9D9',
        marginTop: { lg: '150px', xs: '40px' },
      }}
    >
      <Separator />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: { lg: '-280px', xs: '-150px' } }}
        position="relative"
        zIndex={7}
      >
        <img
          src={aboutLeadsImage}
          alt="Imagem decorativa explicando a estratégia de converter mais leads"
        />
      </Box>

      <Container
        sx={{
          marginTop: '30px',
          color: '#000',
        }}
      >
        <Typography
          paragraph
          sx={{
            fontSize: { lg: 24, xs: 18 },
            maxWidth: {
              lg: 1300,
              xs: '100%',
            },
            textAlign: {
              lg: 'left',
              xs: 'center',
            },
          }}
        >
          Um Corretor de Imóveis deve estar atualizado sobre as tendências do
          mercado mercado imobiliário. Isso inclui conhecer os preços, as áreas
          em crescimento, as demandas dos compradores e as{' '}
          <Typography
            component="span"
            fontWeight={600}
            sx={{ fontSize: { lg: 24, xs: 18 } }}
          >
            características específicas da carteira de imóveis disponível.
          </Typography>{' '}
          O conhecimento sólido do mercado permite que o corretor ofereça
          informações precisas e relevantes aos clientes.
        </Typography>

        <Typography
          paragraph
          sx={{
            fontSize: { lg: 24, xs: 18 },
            maxWidth: {
              lg: 1300,
              xs: '100%',
            },
            textAlign: {
              lg: 'left',
              xs: 'center',
            },
          }}
        >
          No mundo acelerado de hoje, a velocidade é essencial. Os leads esperam
          respostas rápidas. Um corretor ágil, que responde prontamente a
          consultas, agendamentos e dúvidas, tem mais chances de fechar
          negócios.
        </Typography>

        <Stack
          direction={{ lg: 'row', xs: 'column' }}
          justifyContent="space-between"
          sx={{
            marginTop: {
              lg: '138px',
              xs: '50px',
            },
          }}
        >
          <Typography
            component="strong"
            sx={{
              fontSize: {
                lg: 40,
                xs: 20,
              },
              maxWidth: 523,
              margin: {
                lg: '0',
                xs: '0 auto',
              },
              textAlign: 'center',
            }}
          >
            O{' '}
            <Typography
              component="span"
              fontWeight={700}
              sx={{
                fontSize: {
                  lg: 40,
                  xs: 20,
                },
              }}
            >
              GIMOV
            </Typography>{' '}
            é a ferramenta que faltava para auxiliar na conversão de leads.
          </Typography>

          <img src={aboutLeadsDevicesImage} alt="Imagem de dispositivos" />
        </Stack>
      </Container>

      <Box
        sx={{
          marginTop: '-100px',
        }}
      >
        <div className="bottom-separator" />
      </Box>
    </Box>
  )
}
