import { ThanksPage } from 'components'
import { Checkout2 } from 'pages/Checkout2/checkout2'
import { ErrorPage } from 'pages/ErrorPage/errorPage'
import { Lp } from 'pages/Lp'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

export const AppRoutes = () => {
  const routes = [
    {
      path: '/',
      element: <Lp />,
    },
    {
      path: 'checkout/:id',
      element: <Checkout2 />,
    },
    {
      path: 'obrigado/:id',
      element: <ThanksPage />,
    },
    {
      path: '*',
      element: <ErrorPage />,
    },
  ]

  return <RouterProvider router={createBrowserRouter(routes)} />
}
