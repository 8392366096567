import { forwardRef } from 'react'
import { InputProps } from './InputProps.types'

// eslint-disable-next-line react/display-name
export const Input = forwardRef(
  (
    {
      onChange,
      onBlur,
      name,
      label,
      error = '',
      className,
      type = 'text',
      disabled = false,
      maxLength,
      required,
      id,
    }: InputProps,
    ref,
  ) => (
    <div className="relative">
      <input
        ref={ref as React.LegacyRef<HTMLInputElement>}
        disabled={disabled}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        required={required}
        id={id}
        maxLength={maxLength}
        className={`${className} block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 peer`}
        placeholder=" "
      />
      <label
        htmlFor={id}
        className="absolute text-xs md:text-sm text-gray-500 dark:text-gray-400 duration-300 pointer-events-none transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
      >
        {label}
      </label>
      {error?.length > 0 && <p className="text-xs text-red-600">{error}</p>}
    </div>
  ),
)
