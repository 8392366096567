import { Container, Typography, Stack } from '@mui/material'

export const Footer: React.FC = () => {
  return (
    <footer>
      <Container
        sx={{
          marginTop: '90px',
        }}
      >
        <Stack
          direction={{
            lg: 'row',
            xs: 'column',
          }}
          justifyContent="space-between"
          gap={0.9}
        >
          <Typography
            component="strong"
            sx={{
              fontSize: 15,
            }}
          >
            GATE 51 - Sistemas de Informação
          </Typography>

          <Typography
            component="strong"
            sx={{
              fontSize: 15,
            }}
          >
            CNPJ: 34.127.298/0001-17
          </Typography>

          <Typography
            component="strong"
            sx={{
              fontSize: 15,
            }}
          >
            Contato: contato@gate51.com.br
          </Typography>
        </Stack>
      </Container>
    </footer>
  )
}
