import { Input } from 'components/Input'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { removeSpaces } from 'utils/removeMask'
import { expiryDate, formatCreditCard } from 'utils/masks'
import { CaretCircleLeft } from '@phosphor-icons/react'
import { Termos } from 'components/Termos/Termos'
import { getCardBin } from 'controllers/creditCardController'

export function SelFormaPgmto({
  plan,
  isFormPmto,
  setIsFormPmto,
  client,
  setDataSubscription,
  setShowModal,
}) {
  const [paymentType, setPaymentType] = useState('')
  const [bin, setBin] = useState('')
  const [binData, setBinData] = useState()
  const [isLoadingBin, setIsLoadingBin] = useState(false)
  const [card, setCard] = useState()
  const [erroNrCard, setErroNrCard] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [hasError, setHasError] = useState(null)

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm()

  async function getApiBinData(bin) {
    bin = removeSpaces(bin)
    // check the length of the input. It must have at least 6 digits
    if (removeSpaces(bin).length < 16) {
      setBinData(null)
      setHasError('Número de Cartão de Crédito inválido!')
      return
    }
    try {
      const _bin = await getCardBin(bin)
      // console.log('_bin Retorno', _bin)
      if (_bin) {
        setBinData(_bin)
        setErroNrCard(false)
        setHasError(null)
      } else {
        setBinData(null)
        setErroNrCard(true)
        setHasError('Número de Cartão de Crédito inválido!')
      }

      // const _bin = await apiGate.get('/bin/' + bin)
      // if (_bin.status === 200) {
      //   setBinData(_bin.data)
      //   setErroNrCard(false)
      //   setHasError(null)
      //   // console.log('b:', _bin.data)
      // } else {
      //   setBinData(null)
      //   setErroNrCard(true)
      //   setHasError('Número de Cartão de Crédito inválido!')
      // }
    } catch (err) {
      // console.error(err)
      setHasError('Número de Cartão de Crédito inválido!')
    }
  }
  async function onSubmit(data) {
    if (!hasError) {
      if (paymentType === 'credit_card') {
        data.brand = binData.brand
      }
      const dados = {
        client,
        card: data,
        plan,
        payment_method: paymentType,
      }
      setDataSubscription(dados)
      setShowModal(true)
    }
  }

  function handleChangePaymentType(e) {
    setPaymentType(e.target.id)
    if (e.target.id === 'boleto') {
      clearErrors('credit_card')
      clearErrors('card_name')
      clearErrors('expiry_date')
      clearErrors('cvv')
      setHasError(null)
    }
  }
  const [agree, setAgree] = useState(false)
  const handleAgree = (event) => {
    if (event.target.checked) {
      setAgree(true)
    } else {
      setAgree(false)
    }
  }
  const [openModal, setOpenModal] = useState(false)
  const [tpModal, setTpModal] = useState('T')
  const openModalView = (open, tp) => {
    setOpenModal(open)
    setTpModal(tp)
  }
  // console.log(client)
  return (
    <form onSubmit={handleSubmit(onSubmit)} className=" flex-col ">
      <section>
        <div className="text-xs p-1 bg-slate-200 mb-2">
          <div className="flex my-2">
            <input
              type="checkbox"
              id="cbTermoUso"
              name="cbTermoUso"
              required
              onChange={handleAgree}
              className="mx-2"
            />
            <div>Eu lí e concordei com os</div>
            <div
              className="flex mx-1 cursor-pointer underline"
              onClick={() => {
                openModalView(true, 'T')
              }}
            >
              TERMOS DE USO.
            </div>
          </div>
          <div className="flex my-2">
            <input
              type="checkbox"
              id="cbPolPrivate"
              name="cbPolPrivate"
              required
              onChange={handleAgree}
              className="mx-2"
            />
            <div>Eu lí e concordei com a</div>
            <div
              className="flex mx-1 cursor-pointer underline"
              onClick={() => {
                openModalView(true, 'P')
              }}
            >
              POLÍTICA DE PRIVACIDADE.
            </div>
          </div>
        </div>
        <div className="mb-2 text-slate-800 font-bold">
          Selecione a forma de pagamento
        </div>

        <div className="flex flex-col border border-gray-300 rounded-lg divide-y-2 divide-gray-300">
          <div className="px-3 py-5 flex items-center gap-2">
            <input
              className="accent-black/80"
              id="credit_card"
              type="radio"
              name="payment_type"
              onChange={handleChangePaymentType}
              defaultChecked={paymentType === 'credit_card'}
            />
            <label htmlFor="credit_card" className="text-sm font-semibold">
              Pagar com cartão de crédito
            </label>
          </div>
          {paymentType === 'credit_card' && (
            <div className="p-5 flex flex-col gap-3">
              <div className="relative">
                <Input
                  maxLength={19}
                  {...register('credit_card', {
                    minLength: 16,
                    onChange: (e) => {
                      setBin(formatCreditCard(e.target.value))
                    },
                    onBlur: (e) => {
                      setValue('credit_card', formatCreditCard(e.target.value))
                      getApiBinData(formatCreditCard(e.target.value))
                    },
                  })}
                  label="Número do cartão*"
                  error={
                    errors.credit_card || erroNrCard
                      ? 'Insira um número válido'
                      : ''
                  }
                  required
                />
                <img
                  src={binData ? binData.brandImage : ''}
                  className="absolute right-2 top-3 max-w-[40px]"
                  alt=""
                />
                {hasError && (
                  <span className="text-xs text-red-500">{hasError}</span>
                )}
              </div>

              <Input
                {...register('card_name')}
                className="uppercase"
                label="Nome impresso no cartão*"
                minLength={4}
                maxLength={64}
                required
              />

              <div className="grid grid-cols-2 gap-3">
                <Input
                  {...register('expiry_date', {
                    onChange(e) {
                      setValue('expiry_date', expiryDate(e.target.value))
                    },
                  })}
                  label="MM/AA*"
                  maxLength={5}
                  minLength={5}
                  error={errors.expiry_date ? 'Insira uma data válida' : ''}
                  required
                />
                <Input
                  {...register('cvv', {
                    onChange(e) {
                      setValue('cvv', e.target.value)
                    },
                  })}
                  label="CVV*"
                  maxLength={bin.cvv ? bin.cvv : 3}
                  minLength={bin.cvv ? bin.cvv : 3}
                  error={errors.cvv ? 'CVV' : ''}
                  required
                />
              </div>
              <button
                type="submit"
                className={
                  isDisabled
                    ? 'uppercase bg-gray-400 text-xl p-3 rounded-lg text-white font-bold'
                    : 'uppercase bg-primary text-xl p-3 rounded-lg text-white font-bold'
                }
                disabled={isDisabled}
              >
                Confirmar dados da compra
              </button>
            </div>
          )}

          <div className="px-3 py-5 flex items-center gap-2">
            <input
              className="accent-secondary"
              id="boleto"
              type="radio"
              name="payment_type"
              onChange={handleChangePaymentType}
            />
            <label htmlFor="boleto" className="text-sm font-semibold">
              Pagar com Boleto
            </label>
          </div>

          {paymentType === 'boleto' && (
            <div className="p-5 flex flex-col gap-3">
              {/* <p className="text-sm">
                Clique em &ldquo;Finalizar Compra&rdquo; para gerar o Boleto.
              </p>
              <p className="text-sm">Emitir boleto de pagamento...</p> */}

              <button
                type="submit"
                className={
                  isDisabled
                    ? 'uppercase bg-gray-400 text-xl p-3 rounded-lg text-white font-bold'
                    : 'uppercase bg-primary text-xl p-3 rounded-lg text-white font-bold'
                }
                disabled={isDisabled}
              >
                Confirmar dados da compra
              </button>
            </div>
          )}
        </div>

        <div className="mt-6 w-full">
          <button
            className="flex gap-1 items-center text-[#1b1b1b] font-semibold text-lg mb-4"
            type="button"
            onClick={() => setIsFormPmto(false)}
          >
            <CaretCircleLeft color="#1b1b1b" size={32} weight="fill" />
            Voltar
          </button>
        </div>
        {/* <pre>{client}</pre> */}
        <Termos
          tpModal={tpModal}
          openModal={openModal}
          setOpenModal={setOpenModal}
          height={'70vh'}
          width={'70vw'}
        />
      </section>
    </form>
  )
}
