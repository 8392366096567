export function documentType(cpfcnpj) {
  const tam = cpfcnpj
    .replaceAll('.', '')
    .replaceAll('-', '')
    .replaceAll('/', '')
    .trim().length
  if (tam === 11)
    return {
      type: 'individual',
      tpDoc: 'CPF',
      nr: cpfcnpj
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('/', '')
        .trim(),
    }
  if (tam === 14)
    return {
      type: 'company',
      tpDoc: 'CNPJ',
      nr: cpfcnpj
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('/', '')
        .trim(),
    }
}

export function CEPFormat(cep) {
  // console.log('cep', cep.replaceAll('-', ''))
  return cep.replaceAll('-', '')
}
