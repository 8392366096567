import { useNavigate } from 'react-router-dom'
const PayConfirmation = ({ hasError, setShowPayConfirmation, client }) => {
  const navigate = useNavigate()

  function goToGIMOV() {
    window.open(
      'https://gimov.com.br/singup?email=' +
        encodeURIComponent(client.email) +
        '&nome=' +
        encodeURIComponent(client.name),
      '_self',
    )
  }
  function goToLanding() {
    navigate(-1)
  }
  // console.log(hasError)
  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
        <div className="relative w-auto my-6 mx-auto max-w-md">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-primary outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <div className="text-base text-white">Controle de Transação</div>
            </div>
            <div className="relative p-6 flex">
              <form className="bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 w-full">
                {!hasError && (
                  <>
                    <label className="block text-black text-sm text- mb-1 font-bold">
                      Parabéns!
                    </label>
                    <label className="block text-black text-sm text- mb-1">
                      Sua compra foi registrada com sucesso.
                    </label>
                    <label className="block text-black text-sm text- mb-1">
                      Agora é só realizar o cadastro no aplicativo usando o
                      e-mail informado e fazer uso de toda a tecnologia que o{' '}
                      <strong>GIMOV</strong> oferece para ampliar suas vendas.
                    </label>
                    <label className="block text-black text-sm text- mb-1">
                      Dentro de instantes encaminharemos as informações de sua
                      aquisição para o e-email {client.email}.
                    </label>
                  </>
                )}
                {hasError && (
                  <>
                    <label className="block text-red-600 text-sm text- mb-1 font-bold">
                      Atenção!
                    </label>
                    <br />
                    {hasError.response.status !== 521 && (
                      <>
                        <label className="block text-black text-sm text- mb-1">
                          Não foi possível realizar a transação, verifique os
                          dados informados.({hasError.response.data})
                        </label>
                      </>
                    )}
                    {hasError.response.status === 521 && (
                      <label className="block text-black text-sm text- mb-1">
                        O email ({client.email}) informado possui uma assinatura
                        ativa, para adquirir outra, solicite o cancelamento da
                        assinatura vigente ou{' '}
                        <strong>informe outro email</strong>.
                        <br />
                        <br />
                        <strong>Operação Cancelada!</strong>
                      </label>
                    )}
                  </>
                )}
              </form>
            </div>
            {!hasError && (
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  // className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                  className="uppercase bg-primary text-base p-3 rounded-lg text-white  mx-2"
                  type="button"
                  onClick={goToLanding}
                >
                  Sair
                </button>
                <button
                  className="uppercase bg-primary text-base p-3 rounded-lg text-white font-bold"
                  type="button"
                  onClick={goToGIMOV}
                >
                  Ir para o GIMOV
                </button>
              </div>
            )}
            {hasError && (
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  // className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                  className="uppercase bg-primary text-base p-3 rounded-lg text-white  mx-2"
                  type="button"
                  onClick={() => {
                    setShowPayConfirmation(false)
                  }}
                >
                  Voltar
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </>
      ) : null} */}
    </>
  )
}

export default PayConfirmation
