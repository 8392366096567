import { currencyFormater } from 'utils/currencyFormater'

export function InfoPlano({ plan }) {
  function convertCentsToReal(price) {
    if (price) return price / 100
    else return 0
  }
  return (
    <section className="pb-2  text-base flex-1">
      <div className="mt-2 p-2 bg-slate-200">
        <div>
          <strong className="text-slate-800 ">Plano: </strong>
          <strong className="text-slate-800 px-2">{plan?.name}</strong>
        </div>
        <div className="mt-2 flex">
          <div className="text-slate-800 font-bold ">Valor:</div>
          <div className="text-slate-800 font-bold px-3">
            {plan &&
              currencyFormater(
                convertCentsToReal(plan?.items[0]?.pricing_scheme?.price),
              )}
          </div>
        </div>
      </div>
      <div className="mt-2 p-2 bg-slate-200">
        <strong className="py-2 text-slate-800">Descrição:</strong>
        <div className="py-2 text-slate-800 text-xs">{plan?.description}</div>
      </div>
      <div className="mt-2 p-2 bg-slate-200">
        <strong className="py-2 text-red-500">Atenção:</strong>
        <div className="py-2 text-slate-800 text-xs">
          É necessário possuir o número do registro no Conselho Regional de
          Corretores de Imóveis (CRECI) da sua região, para uso do sistema.
        </div>
      </div>
    </section>
  )
}
