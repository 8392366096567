/* eslint-disable react/no-unescaped-entities */
import { Box, Typography } from '@mui/material'

export default function TermodeUso() {
  return (
    <Box>
      <Typography component="h1" variant="h6">
        TERMOS E CONDIÇÕES DE USO
      </Typography>
      <br />
      <Typography variant="subtitle1">
        Olá. Bem-vindo aos termos e condições de uso do GIMOV ("Termos"). <br />
        Os Termos abaixo dispostos são importantes pois eles:
        <br />
        Indica os direitos legais do Usuário no GIMOV;
        <br />
        Explicam os direitos que o Usuário nos concede ao usar o GIMOV; e,
        <br />
        Descrevem as regras que todos precisam seguir ao usar o GIMOV.
        <br />
        Por favor leia estes Termos, nossa Política de Privacidade e quaisquer
        outros termos mencionados neste documento com cuidado.
        <br />A presente versão desta Política foi atualizada pela última vez
        em: 23/06/2023. V.1.0.
      </Typography>
      <br />
      <Typography variant="subtitle1"> 1. Introdução</Typography>
      Obrigado por escolher o GIMOV ("GIMOV," "nós", "nosso"). GIMOV é uma
      plataforma para{' '}
      <b>Corretores de Imóveis, Incorporadoras, Construtoras e Imobiliárias</b>,
      que oferece serviços para aqueles que desejam desburocratizar e tornar o
      processo de vendas mais rápido e transparente para todos. Ao inscrever-se
      ou usar qualquer um dos nossos Serviços, inclusive todos os recursos e
      funcionalidades, websites e interfaces de usuário associados, bem como os
      aplicativos de conteúdo e software associados aos nossos serviços
      ("Serviço"), ou acessar qualquer conteúdo ou material disponibilizado pelo
      serviço, incluindo, mas não se limitando a, toda informação, texto,
      mensagens, imagens, fotos, ilustrações, design, ícones, clipes de vídeo,
      sons, arquivos, marcas, materiais de direito autoral, adornos comerciais,
      software, especificações, catálogos, literatura, informação técnica,
      propaganda ou materiais ("Conteúdo") você estará aderindo a um contrato
      vinculativo com a pessoa jurídica GIMOV, indicada na Seção 12 “Fale
      Conosco”. Seu contrato com GIMOV inclui estes e outros eventuais termos
      adicionais com os quais você concorde, além de termos com quaisquer
      terceiros ("Acordos"). Os Acordos incluem termos relativos a futuras
      alterações dos Acordos, limitações de responsabilidade, privacidade,
      renúncia a ações coletivas e resolução de disputas por arbitragem ao invés
      de um tribunal. A partir do momento em que aceitar os presentes Termos,
      você será considerado um usuário (“Usuário”). Para acessar aos Termos, a
      versão atual e efetiva dos Acordos pode ser encontrada no site do GIMOV.
      Ao contratar os Serviços, o Usuário atesta que leu, compreende e aceita os
      Acordos, e concorda em ficar vinculado por eles. A concordância com os
      Termos são imprescindíveis para o uso dos Serviços. Para usar os Serviços
      e acessar qualquer conteúdo, o Usuário precisa (1) ser um corretor de
      imóveis habilitado ou em processo de habilitação para exercer a função,
      (2) ter o poder para celebrar um contrato vinculativo conosco e ser
      desimpedido de assim fazê-lo pelas leis aplicáveis e (3) residir em um
      país onde os Serviços estiverem disponíveis. O Usuário declara, ainda, que
      toda e qualquer informação de registro que enviar para o GIMOV é
      verdadeira, precisa e completa, e que se compromete em mantê-las assim em
      todos os momentos.
      <br />
      <Typography variant="subtitle1">2. Alterações nos Acordos</Typography>
      Ocasionalmente, o GIMOV poderá alterar as disposições dos Acordos, sem
      aviso prévio, com o propósito de melhorar as funções e/ou recursos
      existentes ou acrescentar novas funções e/ou recursos aos Serviços;
      implementar avanços científicos e tecnológicos, além de ajustes técnicos
      razoáveis dos Serviços, assegurando a operacionalidade e a segurança dos
      mesmos, seja por razões legais ou regulamentares. Estas modificações serão
      incorporadas aos Termos imediatamente e serão comunicadas ao Usuário
      mediante envio de notificação proeminente, confirmação dentro dos Serviços
      ou e-mail indicando as alterações realizadas. Ao usar e acessar os
      Serviços, o Usuário está tacitamente aceitando os Termos que estiverem em
      vigor. Portanto, recomenda-se que as notificações enviadas sejam lidas
      cuidadosamente. Caso o Usuário não queira continuar utilizando os Serviços
      sob a nova versão dos Acordos, o mesmo poderá encerrar sua conta entrando
      em contato conosco.
      <br />
      <Typography variant="subtitle1">3. Usando nosso serviço</Typography>
      Os Serviços são de propriedade da GATE 51 DESENVOLVIMENTO DE SISTEMA DE
      INFORMAÇÃO LTDA . Será concedida ao Usuário uma permissão limitada, não
      exclusiva e revogável para fazer uso dos Serviços; e uma permissão
      limitada, não exclusiva e revogável para fazer uso pessoal e não comercial
      do Conteúdo ("Acesso"). Este Acesso permanecerá em vigor, a menos que seja
      encerrado pelo Usuário ou pelo GIMOV. O Usuário atesta e concorda que
      estará usando os Serviços e o Conteúdo para seu próprio uso pessoal, não
      comercial, e que não irá redistribuir nem transferir os Serviços ou o
      Conteúdo a terceiros. O Conteúdo e os aplicativos de software GIMOV não
      são vendidos nem transferidos ao Usuário, e a GATE 51 DESENVOLVIMENTO DE
      SISTEMA DE INFORMAÇÃO LTDA , bem como os seus licenciantes, retêm a
      titularidade de todas as cópias dos aplicativos de software GIMOV e do
      Conteúdo, mesmo após a instalação em seus computadores pessoais, telefones
      celulares, tablets, dispositivos do tipo wearable e/ou outros dispositivos
      ("Dispositivos"). Todas as marcas, nomes comerciais, logotipos, nomes de
      domínio do GIMOV e quaisquer outras características da marca GIMOV
      ("Características da Marca GIMOV") são de propriedade exclusiva da GATE 51
      DESENVOLVIMENTO DE SISTEMA DE INFORMAÇÃO LTDA . Os Acordos não concedem
      aos Usuários direito algum de usar quaisquer Características da Marca
      GIMOV, seja para uso comercial ou não. O Usuário concorda em respeitar as
      Diretrizes do Usuário e não usar os Serviços, o Conteúdo ou qualquer parte
      dos mesmos de qualquer forma não expressamente permitida pelos Acordos.
      Exceto pelos direitos expressamente concedidos ao Usuário nos Acordos, o
      GIMOV não concede ao Usuário qualquer direito, título ou interesse
      relativos aos Serviços ou ao Conteúdo. Softwares de terceiros (como, por
      exemplo, bibliotecas de software de código aberto) incluídos nos Serviços
      serão disponibilizados ao Usuário consoante os termos da licença aplicável
      de biblioteca do respectivo software, conforme publicada na Seção de Ajuda
      ou Configurações do nosso desktop e cliente móvel e/ou no nosso site.
      <br />
      <Typography variant="subtitle1">
        4. Direitos que Usuário concede ao GIMOV
      </Typography>
      O GIMOV está comprometido em manter a privacidade e segurança de todas as
      informações não publicáveis, entendidas assim, mas não se limitando a
      aquelas relacionadas aos dados e informações pessoais que o Usuário
      fornecer ao GIMOV, conforme sua Política de Privacidade. O GIMOV recomenda
      expressamente que o Usuário releia a Política de Privacidade do GIMOV. Se
      o Usuário fornecer feedback, ideias ou sugestões para o GIMOV relacionados
      aos Serviços ou Conteúdo ("Feedback"), o mesmo reconhece que o Feedback
      não é confidencial e autoriza o GIMOV a usar esse Feedback sem restrições
      e sem pagamentos ao Usuário. O Feedback é considerado um tipo de Conteúdo
      de Usuário.
      <br />
      <Typography variant="subtitle1">5. Diretrizes de Usuário</Typography>
      O GIMOV respeita os direitos de propriedade intelectual e espera que o
      Usuário faça o mesmo. Por isso, foram estabelecidas algumas regras básicas
      para que o Usuário possa seguir utilizando os Serviços, de modo que o
      GIMOV permaneça agradável para todos. O Usuário deve seguir essas regras e
      incentivar outros usuários a fazer o mesmo. É proibido, por qualquer
      motivo:
      <br />
      copiar, redistribuir, transferir, ou tornar público, qualquer parte dos
      Serviços ou do Conteúdo, assim como fazer qualquer uso dos Serviços ou do
      Conteúdo que não seja expressamente permitido pelos Acordos ou pela lei
      aplicável, ou que, de alguma forma, viole os direitos de propriedade
      intelectual (como direitos autorais) relativos aos Serviços ou ao Conteúdo
      ou qualquer parte do mesmo; realizar engenharia reversa, descompilar,
      desmontar, modificar, decodificar ou criar trabalhos derivados dos
      Serviços, do Conteúdo ou de qualquer parte deles, exceto na medida
      permitida pela lei aplicável. Se a lei aplicável permitir que o Usuário
      descompile qualquer parte dos Serviços ou do Conteúdo, quando necessário,
      a fim de obter as informações necessárias para criar um programa
      independente que possa ser operado com os Serviços ou com outro programa,
      as informações que o Usuário obtiver de tais atividades (a) somente
      poderão ser usadas para o objetivo explicado acima, (b) não poderão ser
      divulgadas nem comunicadas sem o consentimento prévio, por escrito, do
      GIMOV para qualquer terceiro a quem não seja necessário divulgar ou
      comunicar para atingir o objetivo em questão e (c) não poderão ser
      utilizados para criar qualquer software ou serviço substancialmente
      similar em sua expressão a qualquer parte dos Serviços ou do Conteúdo;
      contornar qualquer tecnologia utilizada pelo GIMOV, pelos seus
      licenciantes ou por terceiros para proteger o Conteúdo ou os Serviços;
      vender, alugar, sublicenciar ou locar qualquer parte dos Serviços ou do
      Conteúdo; fornecer sua senha para qualquer outra pessoa ou usar o nome de
      usuário e senha de qualquer outra pessoa; realizar "crawling" dos Serviços
      ou utilizar quaisquer meios automatizados (incluindo bots, scrapers e
      spiders) para visualizar, acessar ou coletar informações do GIMOV ou dos
      Serviços; que interfira, interrompa, manipule, viole ou tente sondar,
      varrer ou testar a vulnerabilidade dos Serviços ou dos sistemas de
      computador, rede e regras de uso do GIMOV, ou qualquer componente de
      segurança, medidas de autenticação ou quaisquer outras medidas de proteção
      aplicáveis aos Serviços, ao Conteúdo ou a qualquer parte dos mesmos; ou,
      que conflite com os Acordos.
      <br />
      O uso indevido dos Serviços e do Conteúdo protegido configurará flagrante
      infração à legislação pertinente, ficando o Usuário ou terceiros que
      fizerem tal uso sujeitos às devidas medidas judiciais e/ou extrajudiciais
      que se fizerem necessárias, sem prejuízos de indenização por perdas e
      danos causados e honorários advocatícios eventualmente incorridos,
      conforme previsto na Seção 10.
      <br />
      Todo o Conteúdo encontrado no GIMOV é de propriedade e/ou posse do GIMOV
      ou seus usuários, salvo indicação em contrário. O GIMOV concede ao Usuário
      uma licença limitada, a título precário e não exclusiva, para acessar e
      utilizar o GIMOV e seu Conteúdo unicamente para propósitos informativos,
      pessoais e não comerciais.
      <br />
      Além disso, o Usuário não poderá, sob qualquer pretexto, transmitir
      qualquer Conteúdo:
      <br />
      não relacionado a assuntos pertinentes ao GIMOV; que seja ilícito, nocivo,
      opressivo, vulgar, obsceno, desonesto, que invada a privacidade de
      terceiros, que incite o ódio ou a discriminação racial, étnica, religiosa
      ou social; que constitua uma infração de qualquer direito de propriedade
      intelectual, incluindo, mas não se limitando a marca, patente, segredo
      comercial, direitos autorais ou outro direito relacionado à propriedade
      intelectual de qualquer parte; ou, que violar, intencionalmente ou não,
      qualquer dispositivo legal aplicável ou quaisquer regulamentações que
      tenham a força da lei. Ao transmitir conteúdos ao GIMOV, o Usuário afirma
      e garante que tem o direito de ceder e transferir ao GIMOV, como de fato
      cede e transfere, uma licença irrevogável, por tempo indeterminado, não
      exclusiva e gratuita para que o GIMOV possa usar, copiar, exibir e
      distribuir o Conteúdo, além de preparar trabalhos derivados dele, ou deles
      livremente dispor. Ademais, ao divulgar o Conteúdo em qualquer área
      pública do GIMOV, o Usuário concede ao GIMOV todos os direitos necessários
      para proibir qualquer agregação, exibição, cópia, reprodução, ou
      exploração subsequente do Conteúdo no GIMOV, por qualquer terceiro para
      qualquer propósito. O Usuário está ciente de que o GIMOV não filtra ou
      aprova o Conteúdo de terceiros e que o GIMOV possui o direito (mas não a
      obrigação) de recusar, modificar ou apagar Conteúdo do GIMOV, de acordo
      com seus próprios critérios. A senha protege a respectiva conta, sendo o
      Usuário único e inteiramente responsável por mantê-la em confidencialidade
      e segurança. O Usuário entende que é responsável por todo o uso (incluindo
      aquele não autorizado) do seu nome de usuário e senha nos Serviços. Caso o
      nome de usuário ou a senha do Usuário seja perdido ou roubado, ou, ainda,
      caso acredite que houve um acesso não autorizado à sua conta por um
      terceiro, o Usuário deve notificar o GIMOV imediatamente e alterar sua
      senha assim que possível.
      <br />
      <Typography variant="subtitle1">
        6. Limitações e Modificações do Serviço
      </Typography>
      O GIMOV empregará esforços razoáveis para manter os Serviços operacionais.
      No entanto, certas dificuldades técnicas, manutenção, testes ou
      atualizações necessárias para refletir alterações relevantes na legislação
      e requisitos normativos, podem resultar em interrupções temporárias. O
      GIMOV se reserva no direito de, periodicamente e a qualquer momento,
      modificar ou descontinuar, temporária ou permanentemente, funções e
      recursos dos Serviços, com notificações antecedentes sempre que possível,
      sem incutir responsabilidades ao Usuário, exceto quando proibido por lei;
      por razões válidas, como no caso de interrupção, modificação ou
      descontinuação genuína dos Serviços ou qualquer função ou recurso do
      mesmo, ou a necessidade de reparar, manter ou melhorar as funções ou
      recursos existentes; para adicionar novas funções ou recursos aos
      Serviços; para implementar os avanços científicos e tecnológicos ou
      garantir a operabilidade ou a segurança dos mesmos. O GIMOV e/ou os
      proprietários de qualquer Conteúdo poderão, a qualquer momento, remover
      qualquer Conteúdo, a qualquer momento, sem aviso prévio.
      <br />
      <Typography variant="subtitle1">7. Aviso de Garantia</Typography>
      O Usuário entende e concorda que os Serviços são fornecidos "como estão" e
      "conforme disponível", sem garantia expressa ou implícita ou condição de
      qualquer tipo. O GIMOV e todos os proprietários do Conteúdo se isentam de
      quaisquer garantias ou condições de qualidade satisfatória,
      comerciabilidade, adequação a um propósito particular ou não-violação.
      Tanto o GIMOV quanto qualquer proprietário do Conteúdo não garantem que os
      Serviços são livres de malware ou outros componentes prejudiciais. Além
      disso, o GIMOV não faz nenhuma representação nem garante, endossa,
      assegura ou assume responsabilidade por quaisquer aplicativos de terceiros
      (ou seu Conteúdo), conteúdo de usuário, dispositivos ou qualquer outro
      produto ou serviço anunciado, promovido ou oferecido por terceiros no ou
      através do Serviço GIMOV ou qualquer site vinculado por hiperlink, ou
      destacado em qualquer banner ou outro anúncio. A GATE 51 DESENVOLVIMENTO
      DE SISTEMA DE INFORMAÇÃO LTDA não é responsável nem será responsabilizada
      por qualquer transação entre Usuários e terceiros fornecedores do acima
      exposto. Nenhum conselho ou informação, seja verbal ou escrito, obtido
      pelo Usuário por parte do GIMOV, criará garantias em nome deste.
      <br />
      <Typography variant="subtitle1">8. Limitação</Typography>
      O Usuário concorda que o seu único e exclusivo recurso para quaisquer
      problemas ou qualquer insatisfação com os Serviços é desinstalar qualquer
      software do GIMOV e parar de usar o mesmo. O Usuário concorda que o GIMOV
      não possui obrigação ou responsabilidade decorrentes ou relacionados ao
      conteúdo de terceiros disponibilizados através dos Serviços ou em conexão
      com os Serviços.
      <br />
      Em nenhum caso, o GIMOV, seus gestores, sócios, funcionários, agentes,
      diretores, subsidiárias, afiliadas, sucessores, cessionários, fornecedores
      ou licenciantes serão responsáveis por: qualquer perda ou dano (incluindo
      qualquer dano indireto, especial, incidental, punitivo ou exemplar) que
      não for previsível. A perda ou dano é previsível quando é óbvio que vai
      ocorrer ou quando, no momento de celebração do contrato, tanto o GIMOV
      quanto o Usuário sabem que poderia ocorrer; qualquer:
      <br />
      (a) perda de uso;
      <br />
      (b) perda de dados;
      <br />
      (c) perda de negócios;
      <br />
      (d) perda de lucros;
      <br />
      (e) danos aos Dispositivos, na medida em que o Usuário poderia ter evitado
      tal dano ao seguir os Termos para aplicar as atualizações para os Serviços
      ou o Conteúdo, ou caso tal dano seja causado por sua falha em corretamente
      seguir as instruções de instalação ou cumprir com os requisitos mínimos de
      sistema indicados pelo GIMOV; ou
      <br />
      (f) caso decorrente do uso ou incapacidade de uso dos Serviços,
      Dispositivos ou Conteúdo de terceiros, independentemente da teoria legal,
      sem considerar se o GIMOV foi avisado da possibilidade de tais danos,
      ainda que um recurso falhe em seu propósito essencial.
      <br />
      Nada nos Acordos remove ou limita a responsabilidade do GIMOV por fraudes,
      declarações fraudulentas, óbito ou danos pessoais causados por negligência
      e, se previsto pela lei aplicável, negligência grave.
      <Typography variant="subtitle1">9 Cessão</Typography>O GIMOV pode ceder os
      Acordos e quaisquer direitos do GIMOV ao abrigo dos Acordos, no todo ou em
      parte, e delegar quaisquer obrigações suas ao abrigo dos Acordos. O
      Usuário não pode ceder os Acordos, no todo ou em parte, nem transferir ou
      sublicenciar seus direitos ao abrigo dos Acordos a terceiros.
      <Typography variant="subtitle1">10. Indenização</Typography>O Usuário
      concorda em indenizar e manter o GIMOV indene contra todos os danos,
      perdas e despesas de qualquer natureza (incluindo custos e honorários
      advocatícios) decorrentes de, ou relacionados com: (1) violação, de sua
      parte, dos Acordos ou qualquer um desses; (2) qualquer Conteúdo que o
      Usuário postar ou com o qual contribuir; (3) qualquer atividade na qual o
      Usuário se envolva no ou através dos Serviços; e (4) violação, pelo
      Usuário, de qualquer lei ou dos direitos de terceiros.
      <Typography variant="subtitle1">
        11. Eleição do foro, arbitragem obrigatória e juízo
      </Typography>
      <br />
      11.1 Lei / Jurisdição Regentes
      <br />O Usuário e o GIMOV concordam com a jurisdição do Foro da Comarca de
      Porto Alegre, RS, Brasil, para dirimir qualquer disputa, reivindicação ou
      controvérsia advinda no âmbito dos Acordos (e quaisquer disputas/demandas
      extracontratuais decorrentes de ou em conexão com os mesmos).
      <br />
      Nada contido nos Termos impedirá o direito da GATE 51 DESENVOLVIMENTO DE
      SISTEMA DE INFORMAÇÃO LTDA de cumprir com quaisquer pedidos de execução
      governamentais, de tribunais ou derivados de lei, ou requisitos
      relacionados ao seu acesso ou uso do GIMOV ou informação fornecida ou
      recolhida pela GATE 51 DESENVOLVIMENTO DE SISTEMA DE INFORMAÇÃO LTDA a
      respeito de tal uso. Estes Termos não afetam os direitos legais do Usuário
      enquanto consumidor.
      <br />
      11.2 Renúncia a Ação Coletiva
      <br />
      Quando permitido pela lei aplicável, o Usuário e o GIMOV concordam que
      cada um poderá mover ações contra o outro apenas em sua capacidade
      individual e não como um autor ou membro de classe em qualquer ação
      coletiva ou representativa. A menos que o Usuário e o GIMOV concordem,
      nenhum árbitro ou juiz poderá consolidar reivindicações de mais de uma
      pessoa ou presidir sobre qualquer forma processo representativo ou de
      classe.
      <Typography variant="subtitle1">12. Fale Conosco</Typography>
      Se o Usuário tiver alguma dúvida sobre o Serviço GIMOV ou os Acordos envie
      um email para cleber@cleberdemetrio.adv.br ou mensagem para o whatsapp
      51.981925862.
      <br />
      GIMOV
      <br />
      © 2023 GATE 51 DESENVOLVIMENTO DE SISTEMA DE INFORMAÇÃO LTDA - Todos
      direitos reservados
      <br />
      POLÍTICAS DE PRIVACIDADE•TERMOS DE USO
    </Box>
  )
}
