import { Container, Box, Button, Typography, Stack } from '@mui/material'
import {
  moreLeadsImage,
  IntroBackgroundImage,
  easyAccessImage,
} from '../assets'
import { Separator } from './Separator'

export const Intro: React.FC = () => {
  return (
    <>
      <Separator />
      <Box
        sx={{
          backgroundImage: `url(${IntroBackgroundImage})`,
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          paddingBlock: { lg: '65px', xs: '13px' },
          paddingInline: { lg: '180px', sm: '40px' },
          marginTop: '-150px',
        }}
      >
        <Container>
          <Stack
            direction={{ lg: 'row', xs: 'column' }}
            spacing={{ lg: '8.125rem', xs: '30px' }}
            position="relative"
            zIndex={6}
          >
            <Stack direction="column" justifyContent="center">
              <Typography
                component="strong"
                fontWeight={800}
                sx={{
                  fontSize: {
                    lg: 40,
                    xs: 20,
                  },
                  maxWidth: 640,
                  margin: {
                    lg: '0',
                    xs: '0 auto',
                  },
                  textAlign: {
                    lg: 'left',
                    xs: 'center',
                  },
                }}
              >
                TENHA EM MÃOS TODAS AS INFORMAÇÕES PARA AUMENTAR SUAS VENDAS.
              </Typography>

              <Typography
                component="strong"
                fontWeight={800}
                sx={{
                  fontSize: {
                    lg: 40,
                    xs: 20,
                  },
                  maxWidth: 637,
                  margin: {
                    lg: '0',
                    xs: '10px auto',
                  },
                  textAlign: {
                    lg: 'left',
                    xs: 'center',
                  },
                }}
              >
                AUMENTE SUAS CONVERSÕES USANDO A TECNOLOGIA A SEU FAVOR.
              </Typography>

              <Button
                variant="contained"
                href="#plans"
                sx={{
                  backgroundColor: '#ffffff',
                  color: '#000000',
                  fontWeight: 600,
                  fontSize: { lg: 24, xs: 18 },
                  margin: {
                    lg: '90px auto 0 auto',
                    xs: '20px auto 0 auto',
                  },
                  maxWidth: 293,
                  paddingInline: '17px',
                  ':hover': {
                    backgroundColor: '#000000',
                    color: '#ffffff',
                  },
                }}
              >
                Quero vender mais
              </Button>
            </Stack>

            <Stack direction="column">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src={moreLeadsImage}
                  alt="homem e mulher fechando um negócio"
                />
                <Typography
                  paragraph
                  sx={{
                    marginLeft: 1,
                    fontSize: 20,
                    maxWidth: 310,
                  }}
                >
                  Converta mais leads em sonhos realizados, usando o{' '}
                  <Typography component="span" fontWeight={700}>
                    GIMOV.
                  </Typography>
                </Typography>
              </Box>

              <Box
                mt={{ lg: '176px', xs: '30px' }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src={easyAccessImage}
                  alt="Celular mostrando uma localização na cidade"
                />
                <Typography
                  paragraph
                  sx={{
                    marginLeft: 1,
                    fontSize: 20,
                    maxWidth: 310,
                  }}
                >
                  Acesse facilmente todas as informações de sua carteira de
                  imóveis e amplie suas vendas.
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Box
        sx={{
          marginTop: '-180px',
        }}
      >
        <div className="bottom-separator" />
      </Box>
    </>
  )
}
