import { Separator } from './Separator'
import {
  Container,
  Box,
  Typography,
  Stack,
  ListItem,
  Button,
  List,
} from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { PlansDetails } from '../constants'

type PlanInfoProps = {
  title: string
  info: string
}

type PlanCardProps = {
  type: string
  price: number | string
  infos: PlanInfoProps[]
}

const PlanCard: React.FC<PlanCardProps> = ({ type, price, infos }) => (
  <Box
    sx={{
      padding: '30px',
      backgroundColor: '#161a26',
      maxWidth: 600,
      border: '1px solid #333',
      borderRadius: '12px',
      ':hover': {
        transform: 'scale(1.02)',
      },
      transition: 'transform 0.2s ease-in-out',
    }}
  >
    <Stack alignItems="center">
      <Typography fontWeight={700} component="strong" sx={{ fontSize: 24 }}>
        {type}
      </Typography>
      <Typography component="span">{price} / pagamento mensal </Typography>
      <List>
        {infos.map((info, index) => (
          <ListItem
            key={index + 1}
            sx={{
              display: 'flex',
              gap: 0.9,
              alignItems: 'center',
              fontSize: 14,
            }}
          >
            <CheckCircleRoundedIcon /> {info.title} {info.info}{' '}
          </ListItem>
        ))}
      </List>
      <Button
        href={`/checkout/${type.toLowerCase()}`}
        variant="contained"
        sx={{
          backgroundColor: '#ffffff',
          color: '#000000',
          fontWeight: 600,
          fontSize: { xl: 24, xs: 18 },
          marginTop: {
            lg: '40px',
            xs: '20px',
          },
          maxWidth: 413,
          paddingInline: '13px',
          ':hover': {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
        }}
      >
        Quero Assinar!
      </Button>
    </Stack>
  </Box>
)

export const Plans: React.FC = () => {
  return (
    <section id="plans">
      <Separator />
      <Container
        sx={{
          marginTop: { lg: '-160px', xs: '-133px' },
          position: 'relative',
          zIndex: 6,
        }}
      >
        <Typography
          display="block"
          component="strong"
          sx={{
            fontSize: {
              lg: 40,
              xs: 20,
            },
            textAlign: 'center',
          }}
        >
          Planos de Assinatura
        </Typography>

        <Typography
          display="block"
          component="strong"
          sx={{
            fontSize: {
              lg: 40,
              xs: 20,
            },
            textAlign: 'center',
            marginTop: '14px',
          }}
        >
          <Typography
            fontWeight={700}
            component="span"
            sx={{
              fontSize: {
                lg: 40,
                xs: 20,
              },
            }}
          >
            GIMOV
          </Typography>{' '}
          - Para Corretores de Imóveis
        </Typography>

        <Box
          display="grid"
          sx={{
            gridTemplateColumns: {
              lg: 'repeat(2, 1fr)',
              xs: 'repeat(1, 1fr)',
            },
            gap: '20px',
            marginTop: '20px',
            marginBottom: '20px',
            justifyItems: 'center',
          }}
        >
          {PlansDetails.map((plan: PlanCardProps) => (
            <PlanCard
              key={plan.type}
              type={plan.type}
              price={plan.price.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              infos={plan.infos.map((plan) => plan)}
            />
          ))}
        </Box>
      </Container>
    </section>
  )
}
