import axios from 'axios'
import { CEPFormat, documentType } from 'utils/utils'

export async function getCardBin(bin) {
  const options = {
    method: 'GET',
    url: 'https://api.pagar.me/bin/v1/' + bin,
    headers: {
      accept: 'application/json',
    },
  }
  const _bin = await axios
    .request(options)
    .then(function (response) {
      return response.data
    })
    .catch(function () {
      return null
    })
  return _bin
}

export async function getTokenCard(client, card) {
  try {
    const cardToken = {
      type: 'card',
      card: {
        billing_address: {
          line_1: client.bairro
            ? client.numero + ',' + client.endereco + ',' + client.bairro
            : client.numero + ',' + client.endereco,
          line_2: client.complemento,
          zip_code: CEPFormat(client.cep),
          city: client.cidade,
          state: client.uf,
          country: 'BR',
        },
        number: card.credit_card.replaceAll(' ', ''),
        holder_name: card.card_name.toUpperCase(),
        holder_document: documentType(client.cpf).nr,
        exp_month: Number(card.expiry_date.substring(0, 2)),
        exp_year: Number(card.expiry_date.substring(3)),
        cvv: card.cvv,
        brand: card.brand,
      },
    }

    // console.log(cardToken)

    const options = {
      method: 'POST',
      url: 'https://api.pagar.me/core/v5/tokens',
      params: { appId: process.env.REACT_APP_PK },
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      data: cardToken,
    }
    const token = await axios
      .request(options)
      .then(function (response) {
        return response.data
      })
      .catch(function () {
        return null
      })
    return token
  } catch (e) {
    return null
  }
}
